import React from 'react';
import { Image } from '../image/image';
import { Heading } from '../heading/heading';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import './_service-card-list.scss';
import '../image/_image.scss';

interface ServiceCardListProps {
  cards: ServiceCardProps[];
  title?: string;
}

interface ServiceCardProps {
  title: string;
  url: string;
  image: string;
}

const ServiceCard = ({ title, url, image }: ServiceCardProps) => (
  <li className="service-card-list__item">
    <a className="service-card-list__item-link" href={url}>
      <div className="service-card-list__item-image">
        <Image image={image} />
      </div>
      <div className="service-card-list__item-title">
        <Heading level="h2" size="xs" color="white">
          {title}
        </Heading>
        <ArrowIcon />
      </div>
    </a>
  </li>
);

export const ServiceCardList = ({ cards, title }: ServiceCardListProps) => (
  <div className="service-card-list">
    {title && (
      <div className="service-card-list__title">
        <Heading level="h2" size="sm" color="blue-dark">
          {title}
        </Heading>
      </div>
    )}
    <ul className="service-card-list__items">
      {cards &&
        cards.length !== 0 &&
        cards.map((card) => (
          <ServiceCard key={card.title} {...card}></ServiceCard>
        ))}
    </ul>
  </div>
);
