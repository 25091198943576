import React from 'react';
import { ReactComponent as DoubleArrow } from '../../assets/icons/double-arrow.svg';
import './_breadcrumb.scss';

interface BreadcrumbProps {
  linkToPage: {
    title: string;
    url: string;
  };
}

interface BreadcrumbContainer {
  breadCrumbList: BreadcrumbProps[];
}

const Breadcrumb = ({ linkToPage }: BreadcrumbProps) => {
  const connector = '-';

  return (
    <div className="breadcrumb">
      <a className={`breadcrumb__crumbs`} href={linkToPage.url}>
        {linkToPage.title}
      </a>
      <div className="breadcrumb__connector">{connector}</div>
    </div>
  );
};

export const BreadcrumbsList = ({ breadCrumbList }: BreadcrumbContainer) => (
  <div className="breadcrumb-list">
    <div className="breadcrumb-list__arrow">
      <DoubleArrow />
    </div>
    {breadCrumbList &&
      breadCrumbList.length !== 0 &&
      breadCrumbList.map((crumb) => (
        <Breadcrumb key={crumb.linkToPage.title} {...crumb}></Breadcrumb>
      ))}
  </div>
);
