import React from 'react';
import './_promoted-page.scss';
import { Button } from '../button/button';
import { Heading } from '../heading/heading';
import { Image } from '../image/image';

interface PromotedPageBlockProps {
  title: string;
  image: string;
  button?: {
    url: string;
    text: string;
  };
}

export const PromotedPage = ({
  title,
  button,
  image,
}: PromotedPageBlockProps) => (
  <div className="promoted-page">
    <div className="promoted-page__content">
      <div className="promoted-page__title">
        <Heading level="h2" size="xl" color="blue-dark">
          {title}
        </Heading>
      </div>
      <div className="promoted-page__button">
        {button && button?.text && <Button {...button}></Button>}
      </div>
    </div>
    <div className="promoted-page__image">
      <Image image={image} />
    </div>
  </div>
);
