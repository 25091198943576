import React from 'react';
import './_location-shortcut.scss';
import { Heading } from '../heading/heading';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-red.svg';

interface LocationShortcutsProps {
  heading?: string;
  links?: {
    text: string;
    url: string;
    target: string;
  }[];
}

export const LocationShortcuts = ({
  heading,
  links,
}: LocationShortcutsProps) => {
  return (
    <div className="location-shortcut">
      <div className="location-shortcut__heading">
        <Heading level="h5" size="xs" color="blue-dark">
          {heading}
        </Heading>
      </div>
      <div className="location-shortcut__links">
        <ul>
          {links &&
            links.length > 0 &&
            links.map((link) => (
              <li>
                <div className="location-shortcut__link">
                  <a href={link.url} key={link.text} target={link.target}>
                    {link.text}
                    <div className="location-shortcut__link-arrow">
                      <Arrow />
                    </div>
                  </a>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
