import React from 'react';
import './_large-number.scss';

interface LargeNumberProps {
  stackOfCards: Card[];
}

interface Card {
  label: string;
  largeText: string;
  backgroundColor: string;
}

const LargeNumberCard = ({
  label,
  largeText: shortText,
  backgroundColor,
}: Card) => {
  const cardClassName = `large-number__card large-number__card--${backgroundColor.toLowerCase()}`;
  return (
    <li className={cardClassName}>
      <div className="large-number__card--label">{label}</div>
      <div className="large-number__card--short-text">{shortText}</div>
    </li>
  );
};

export const LargeNumber = ({ stackOfCards }: LargeNumberProps) => (
  <ul
    className={`large-number large-number--${
      stackOfCards.length == 1
        ? 'one-card'
        : stackOfCards.length == 2
        ? 'two-card'
        : stackOfCards.length == 3
        ? 'three-card'
        : null
    }`}
  >
    {stackOfCards &&
      stackOfCards.length !== 0 &&
      stackOfCards.map((card) => (
        <LargeNumberCard key={card.label} {...card}></LargeNumberCard>
      ))}
  </ul>
);
