import React from 'react';
import { Heading } from '../heading/heading';
import { Image } from '../image/image';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-red.svg';
import './_section-entrance-list.scss';

interface SectionEntranceListProps {
  entrances: SectionEntranceProps[];
}

interface SectionEntranceProps {
  title: string;
  url: string;
  image: string;
  text: string;
}

const SectionEntrance = ({ title, url, image, text }: SectionEntranceProps) => (
  <li className="section-entrance-list__item">
    <a className="section-entrance-list__item-link" href={url}>
      <div className="section-entrance-list__item-title-container">
        <div className="section-entrance-list__item-title">
          <Heading level="h2" size="lg" color="blue-dark">
            {title}
          </Heading>
        </div>
        <div className="section-entrance-list__item-icon">
          <Arrow></Arrow>
        </div>
      </div>

      <p className="section-entrance-list__item-text">{text}</p>
    </a>
    <div className="section-entrance-list__item-image">
      <Image image={image} />
    </div>
  </li>
);

export const SectionEntranceList = ({
  entrances,
}: SectionEntranceListProps) => (
  <div className="section-entrance-list">
    <ul className="section-entrance-list__items">
      {entrances &&
        entrances.length !== 0 &&
        entrances.map((entrance) => (
          <SectionEntrance key={entrance.title} {...entrance}></SectionEntrance>
        ))}
    </ul>
  </div>
);
