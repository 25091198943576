import React, { Component, DOMElement, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Footer } from './components/footer/footer';
import { Heading } from './components/heading/heading';
import { Button } from './components/button/button';
import { SystemMessageBlock } from './components/system-message-block/system-message-block';
import { FrontpageHero } from './components/frontpage-hero/frontpage-hero';
import { SectionEntranceList } from './components/section-entrance-list/section-entrance-list';
import { EmployeeHero } from './components/employee-hero/employee-hero';
import { LargeNumber } from './components/large-number/large-number';
import { ServiceCardList } from './components/service-card-list/service-card-list';
import { MegaMenu } from './components/mega-menu/mega-menu';
import {
  ArticleCard,
  ArticleCards,
} from './components/article-card/article-card';
import { BreadcrumbsList } from './components/breadcrumb/breadcrumb';
import { GoogleMap } from './components/google-maps/google-map';
import { Hero } from './components/hero/hero';
import { FactBox } from './components/fact-box/fact-box';
import { LocationCardList } from './components/location-card-list/location-card-list';
import { Promo } from './components/promo/promo';
import { LinkList } from './components/link-list/link-list';
import { CategoryTags } from './components/category-tags/category-tags';
import { Search } from './components/search/search';
import { Quote } from './components/quote/quote';
import { LocationShortcuts } from './components/Location-shortcuts/location-shortcuts';
import { FilterTags } from './components/filter-tags/filter-tags';
import { PromotedPage } from './components/promoted-page/promoted-page';
import { LocationsMap } from './components/locations-map/locations-map';
import { EmployeeCards } from './components/employee-cards/employee-cards';
import { sectrabooking } from './components/sectra-booking/sectra';
import { YoutubeVideos } from './components/video/video';
import { VideoHandler } from './components/video/videoHandler';
import { SimpleLocationsMap } from './components/simple-locations-map/simple-locations-map';

// Add new components here
const ComponentTypes: { [key: string]: any } = {
  Footer: Footer,
  Heading: Heading,
  Button: Button,
  SystemMessageBlock: SystemMessageBlock,
  FrontpageHero: FrontpageHero,
  SectionEntranceList: SectionEntranceList,
  LargeNumber: LargeNumber,
  EmployeeHero: EmployeeHero,
  ServiceCardList: ServiceCardList,
  MegaMenu: MegaMenu,
  ArticleCard: ArticleCard,
  ArticleCards: ArticleCards,
  BreadcrumbsList: BreadcrumbsList,
  GoogleMap: GoogleMap,
  Hero: Hero,
  FactBox: FactBox,
  LocationCardList: LocationCardList,
  Promo: Promo,
  LinkList: LinkList,
  CategoryTags: CategoryTags,
  FilterTags: FilterTags,
  Search: Search,
  Quote: Quote,
  LocationsMap: LocationsMap,
  LocationShortcuts: LocationShortcuts,
  PromotedPage: PromotedPage,
  EmployeeCards: EmployeeCards,
  sectrabooking: sectrabooking,
  YoutubeVideos: YoutubeVideos,
  SimpleLocationsMap: SimpleLocationsMap,
};

document.addEventListener('DOMContentLoaded', function (event) {
  function initialize(componentContainer: HTMLElement) {
    var props = JSON.parse(componentContainer.dataset.props || '{}');

    props.propertyName = componentContainer.dataset.epiPropertyName;
    ReactDOM.render(
      React.createElement(
        ComponentTypes[componentContainer.dataset.reactComponent!],
        props
      ),
      componentContainer
    );
  }

  var reactComponents = document.querySelectorAll<HTMLElement>(
    '[data-react-component]'
  );

  reactComponents.forEach(initialize);
  VideoHandler();
});
