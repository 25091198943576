import React from 'react';
import { Heading } from '../heading/heading';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-red.svg';
import './_location-card-list.scss';

interface LocationCardListProps {
  title?: string;
  locations: LocationCardProps[];
  linkToMore?: {
    text: string;
    url: string;
  };
}

interface LocationCardProps {
  title: string;
  link: string;
  addresses: string[];
  phoneNumber: string;
}

const LocationCard = ({
  title,
  link,
  addresses,
  phoneNumber
}: LocationCardProps) => (
  <li className="location-card-list__item">
    <a className="location-card-list__item-top-link" href={link}>
      <Heading level="h3" size="xs" color="blue-dark">
        {title}
      </Heading>
      <div className="location-card-list__item-content">
        <div className="location-card-list__item-icon">
          {addresses && addresses.length > 0 && <LocationIcon />}
        </div>
        <div>
          {addresses.map((address) => (
            <div key={address}>{address}</div>
          ))}
        </div>
      </div>
    </a>
    <div className="location-card-list__item-content">
      <div className="location-card-list__item-icon">
        {phoneNumber && <PhoneIcon />}
      </div>
      <a className="location-card-list__item-link" href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </a>
    </div>
  </li>
);

export const LocationCardList = ({
  title,
  locations,
  linkToMore,
}: LocationCardListProps) => (
  <div className="location-card-list">
    <div className="location-card-list__content">
      <Heading level="h2" size="sm" color="blue-dark">
        {title}
      </Heading>
      <div className="location-card-list__items-container">
        <ul className="location-card-list__items">
          {locations &&
            locations.length !== 0 &&
            locations.map((location) => (
              <LocationCard key={location.title} {...location}></LocationCard>
            ))}
        </ul>
        {linkToMore && (
          <a className="location-card-list__link" href={linkToMore.url}>
            {linkToMore.text}
            <div className="location-card-list__link-icon">
              <Arrow></Arrow>
            </div>
          </a>
        )}
      </div>
    </div>
  </div>
);
