import { RefObject } from 'react';

import useEvent from './useEvent.hook';

type Handler = (event: MouseEvent) => void;

function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
  excludedClass?: any
): void {
  useEvent(mouseEvent, (event: any) => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (
      !el ||
      el.contains(event.target as Node) ||
      event.target.classList.contains(excludedClass)
    ) {
      return;
    }

    handler(event);
  });
}

export default useClickOutside;
