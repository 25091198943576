import React from 'react';
import { Heading } from '../heading/heading';
import cn from 'classnames';
import './_filter-tags.scss';

interface FilterTagsProps {
  filterTitle?: string;
  options: {
    key: number | string;
    name: string;
    label: string;
    value?: string;
    checked?: boolean;
  }[];
  onChange: (e: any) => void;
  selectedOption: string;
  isSearchPage?: boolean;
}

export const FilterTags = ({
  options,
  onChange,
  selectedOption,
  filterTitle,
}: FilterTagsProps) => {
  return (
    <div className="filter-tags">
      {options && options.length !== 0 && (
        <div>
          <div className="filter-tags__title">
            <Heading level="h2" size="sm" color="blue-dark">
              {filterTitle && filterTitle}
            </Heading>
          </div>
          <div className="filter-tags__list-container">
            <ul className="filter-tags__list">
              <li className="filter-tags__item">
                <input
                  className="filter-tags__input"
                  type="radio"
                  id="all"
                  name="filter-tag-option"
                  value="all"
                  onChange={onChange}
                />
                <label
                  className={cn('filter-tags__label', {
                    'filter-tags__label--is-selected':
                      selectedOption === 'all' || '',
                  })}
                  htmlFor="all"
                >
                  All
                </label>
              </li>
              {options.map((option) => (
                <li className="filter-tags__item">
                  <input
                    className="filter-tags__input"
                    type="radio"
                    id={option.key.toString()}
                    name="filter-tag-option"
                    value={option.key.toString()}
                    onChange={onChange}
                    checked={selectedOption === option.key.toString()}
                  />
                  <label
                    className={cn('filter-tags__label', {
                      'filter-tags__label--is-selected':
                        selectedOption === option.key.toString(),
                    })}
                    htmlFor={option.key.toString()}
                  >
                    {option.value}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
