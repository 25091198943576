import React from 'react';
import { FilterTags } from '../filter-tags/filter-tags';
import { ArticleCards } from '../article-card/article-card';
import { Pagination } from '../pagination/pagination';
import { SearchInput } from '../search-input/search-input';
import './_search.scss';
import cn from 'classnames';

interface SearchProps {
  options?: {
    key: number;
    name: string;
    label: string;
    value?: string;
  }[];

  types?: {
    key: string;
    name: string;
    label: string;
    value?: string;
  }[];

  stackOfCards: Card[];
  readMoreText: string;
  filterTitle: string;
  pageCount: number;
  searchButtonText?: string;
  noResultsText: string;
}

interface Card {
  image: string;
  title: string;
  description: string;
  url: string;
}

export const Search = ({
  options,
  stackOfCards,
  readMoreText,
  filterTitle,
  pageCount,
  searchButtonText,
  types,
  noResultsText,
}: SearchProps) => {
  const url = new URL(`${window.location.href}`);

  const initialSelected = url.searchParams.get('tags');
  const [selectedOption, setSelectedOption] = React.useState(
    initialSelected ? initialSelected : undefined
  );

  const initialSelectedType = url.searchParams.get('type');
  const [selectedType, setSelectedType] = React.useState(
    initialSelectedType ? initialSelectedType : undefined
  );

  const initialSearchQuery = url.searchParams.get('q');
  const [searchQuery, setSearchQuery] = React.useState(
    initialSearchQuery ? initialSearchQuery : ''
  );

  const initialSelectedPage = url.searchParams.get('page');
  const [page, setPage] = React.useState(
    initialSelectedPage ? initialSelectedPage : '1'
  );
  const [currentPageCount, setCurrentPageCount] = React.useState(pageCount);

  const [result, setResult] = React.useState(stackOfCards);

  const [isLoading, setIsLoading] = React.useState(false);

  const setUrl = () => {
    url.searchParams.set('tags', `${selectedOption}`);
    url.searchParams.set('page', `${page}`);
    url.searchParams.set('q', `${searchQuery}`);
    url.searchParams.set('type', `${selectedType}`);
    window.history.pushState(
      { tags: selectedOption, page: page, q: searchQuery, type: selectedType },
      '',
      url
    );
  };

  async function post(tags: string, page: string, q: string, type: string) {
    let path = window.location.href.split('?')[0];

    await fetch(`${path}?tags=${tags}&page=${page}&q=${q}&type=${type}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        setUrl();
        setResult(data.items);
        setCurrentPageCount(data.pageCount);
      });

    setIsLoading(false);
  }

  const onChange = (selectedOption: any, page: any, query: any, type: any) => {
    setIsLoading(true);
    setSelectedOption(selectedOption);
    setPage(page);
    setSearchQuery(query);
    setSelectedType(type);
  };

  React.useEffect(() => {
    window.onpopstate = function (event) {
      const currentUrl = new URL(`${window.location.href}`);
      const initialSelected = currentUrl.searchParams.get('tags');
      const initialSelectedPage = currentUrl.searchParams.get('page');

      window.history.replaceState(
        { tags: initialSelected, page: initialSelectedPage },
        '',
        currentUrl
      );

      window.location.reload();
    };
  }, []);

  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    if (notInitialRender.current) {
      post(selectedOption || '', page, searchQuery || '', selectedType || '');
    } else {
      notInitialRender.current = true;
    }
  }, [selectedOption, page, searchQuery, selectedType]);

  return (
    <div className="search">
      {searchButtonText && (
        <SearchInput
          buttonText={searchButtonText}
          onChange={onChange}
          searchQuery={searchQuery}
        />
      )}
      <div className="search__filters">
        {options && options.length !== 0 && (
          <FilterTags
            options={options}
            onChange={(e) =>
              onChange(
                e.target.value,
                '1',
                searchQuery ? searchQuery : '',
                selectedType ? selectedType : 'all'
              )
            }
            selectedOption={selectedOption ? selectedOption : 'all'}
            filterTitle={filterTitle}
          ></FilterTags>
        )}
        {searchButtonText && types && types.length !== 0 && (
          <FilterTags
            options={types}
            onChange={(e) =>
              onChange(
                selectedOption ? selectedOption : '',
                '1',
                searchQuery ? searchQuery : '',
                e.target.value ? e.target.value : 'all'
              )
            }
            selectedOption={selectedType ? selectedType : 'all'}
            filterTitle="Filter"
            isSearchPage={types && types.length !== null}
          ></FilterTags>
        )}
      </div>
      {isLoading && result.length !== 0 && (
        <div className="search__spinner-container">
          <div className="search__spinner"></div>
        </div>
      )}

      {result && result.length !== 0 && !isLoading && (
        <div
          className={cn('search__result', {
            'search__result--is-loading': isLoading,
          })}
        >
          <ArticleCards readMoreText={readMoreText} stackOfCards={result} />
        </div>
      )}

      {result.length === 0 && !isLoading && (
        <div className="search__no-results">{noResultsText}</div>
      )}

      {currentPageCount > 1 && !isLoading && (
        <Pagination
          currentPage={page ? page : '1'}
          pageCount={currentPageCount}
          onChange={(e) =>
            onChange(
              selectedOption ? selectedOption : 'all',
              e.target.value.replace('page-', ''),
              searchQuery ? searchQuery : '',
              selectedType ? selectedType : 'all'
            )
          }
        />
      )}
    </div>
  );
};
