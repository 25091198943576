import React from 'react';
import { Heading } from '../heading/heading';
import { Image } from '../image/image';
import './_hero.scss';

interface HeroProps {
  title: string;
  description?: string;
  image?: string;
}

export const Hero = ({ title, description, image }: HeroProps) => (
  <div className="hero">
    {image && (
      <div className="hero__image-container">
        <div className="hero__image">
          <Image image={image} />
        </div>
      </div>
    )}
    <div className="hero__title">
      <Heading level="h1" size="xl" color="blue-dark">
        {title}
      </Heading>
    </div>
    {description && (
      <div className="hero__description-container">
        <div className="hero__description">{description}</div>
      </div>
    )}
  </div>
);
