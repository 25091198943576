import React from 'react';
import { GoogleMap } from '../google-maps/google-map';
import { Button } from '../button/button';
import { RichText } from '../rich-text/rich-text';
import { Image } from '../image/image';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { ReactComponent as FaxIcon } from '../../assets/icons/fax.svg';
import { ReactComponent as PersonIcon } from '../../assets/icons/person.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/infodark.svg';
import { ReactComponent as ParkingIcon } from '../../assets/icons/parking.svg';
import './_fact-box.scss';
import cn from 'classnames';
import useAnimateHeight from '../../hooks/useAnimateHeight.hook';

interface FactBoxProps {
  title: string;
  description: string;
  button?: {
    text: string;
    url: string;
  };
  phone?: {
    label: string;
    phoneNumber: string;
  };
  telephoneHours?: {
    label: string;
    info: string;
  };
  openingHours?: {
    label: string;
    info: string;
  };
  mail?: {
    label: string;
    mailAdress: string;
  };
  fax?: {
    label: string;
    faxAddress: string;
  };
  address?: {
    label: string;
    locationName: string;
    adressLines: string[];
  };
  googleMapProps?: {
    apiKey: string;
    position: {
      lat: number;
      lng: number;
    };
    hasZoom?: boolean;
  };
  postalInfo?: {
    label: string;
    info: string;
  };
  clinicManager?: {
    label: string;
    name: string;
    title?: string;
    email?: string;
    phone?: string;
    image?: string;
  };
  additionalInfo?: {
    label?: string;
    info?: string;
  };
  responsible?: {
    label?: string;
    info?: string;
  };
  carParking?: {
    label?: string;
    info?: string;
  };
  showMoreButtonText: string;
  showLessButtonText: string;
}

export const FactBox = ({
  title,
  description,
  button,
  googleMapProps,
  postalInfo,
  openingHours,
  telephoneHours,
  phone,
  mail,
  fax,
  address,
  clinicManager,
  additionalInfo,
  responsible,
  carParking,
  showMoreButtonText,
  showLessButtonText,
}: FactBoxProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [height, setHeight] = React.useState<number | undefined>(
    isOpen ? undefined : 0
  );

  const ref = React.useRef<HTMLDivElement>(null);
  useAnimateHeight(isOpen, setHeight, ref);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div className="factbox">
      <div className="factbox__toptext">
        <div className="factbox__toptext-title">{title}</div>
        <div className="factbox__toptext-description">
          <RichText html={description ? description : ''}></RichText>
        </div>
      </div>
      <div className="factbox__contact">
        {button && button?.text && (
          <div className="factbox__contact-item">
            {button && <Button arrow={true} target={'_blank'} {...button} />}
          </div>
        )}
        {phone && phone?.label && (
          <div className="factbox__contact__item">
            <div className="factbox__contact__item__icon">
              <PhoneIcon />
            </div>
            <div className="factbox__contact__item__content">
              <div className="factbox__contact__item__content__title">
                {phone.label}
              </div>
              <div className="factbox__contact__item__content__block">
                <a href={`tel:${phone.phoneNumber}`}>{phone.phoneNumber}</a>
              </div>
            </div>
          </div>
        )}
        {telephoneHours && telephoneHours.label && (
          <div className="factbox__contact__item">
            <div className="factbox__contact__item__icon">
              <ClockIcon />
            </div>
            <div className="factbox__contact__item__content">
              <div className="factbox__contact__item__content__title">
                {telephoneHours.label}
              </div>
              <div className="factbox__contact__item__content__block">
                <RichText
                  html={telephoneHours.info ? telephoneHours.info : ''}
                ></RichText>
              </div>
            </div>
          </div>
        )}
        <div
          style={{ height }}
          className={cn('factbox__container', {
            'factbox__container--is-open': isOpen,
          })}
          aria-labelledby="show-more"
          hidden={isMobile && !isOpen}
        >
          <div className="factbox__toggle" ref={ref}>
            {openingHours && openingHours.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <ClockIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {openingHours.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    <RichText
                      html={openingHours.info ? openingHours.info : ''}
                    ></RichText>
                  </div>
                </div>
              </div>
            )}
            {mail && mail.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <EmailIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {mail.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    <a href={`mailto:${mail.mailAdress}`}>{mail.mailAdress}</a>
                  </div>
                </div>
              </div>
            )}
            {fax && fax.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <FaxIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {fax.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    {fax.faxAddress}
                  </div>
                </div>
              </div>
            )}
            {address && address.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <LocationIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {address.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    {address.locationName}
                    {address.label &&
                      address.adressLines.map((entry) => (
                        <RichText html={entry}></RichText>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {googleMapProps &&
              googleMapProps.position &&
              googleMapProps.position.lat !== 0 && (
                <div className="factbox__contact-item">
                  <GoogleMap
                    apiKey={googleMapProps.apiKey}
                    center={googleMapProps.position}
                    markerPosition={googleMapProps.position}
                  />
                </div>
              )}
            {carParking && carParking.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <ParkingIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {carParking.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    <RichText
                      html={carParking.info ? carParking.info : ''}
                    ></RichText>
                  </div>
                </div>
              </div>
            )}
            {postalInfo && postalInfo.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <LocationIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {postalInfo.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    <RichText
                      html={postalInfo.info ? postalInfo.info : ''}
                    ></RichText>
                  </div>
                </div>
              </div>
            )}
            {clinicManager && clinicManager.name && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <PersonIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {clinicManager.label}
                  </div>
                  <div
                    className={`factbox__contact__item__content__block${
                      clinicManager.image ? '-image' : ''
                    }`}
                  >
                    {clinicManager.image && (
                      <div className="factbox__contact__item__content__image">
                        <Image image={clinicManager.image} />
                      </div>
                    )}
                    <div className="factbox__contact__item__content__block-personalia">
                      <div>{clinicManager.name}</div>
                      {clinicManager.title && (
                        <span>{clinicManager.title}</span>
                      )}
                      {clinicManager.phone && (
                        <a href={`tel:${clinicManager.phone}`}>
                          {clinicManager.phone}
                        </a>
                      )}
                      {clinicManager.email && (
                        <a href={`mailto:${clinicManager.email}`}>
                          {clinicManager.email}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {additionalInfo && additionalInfo.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <InfoIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {additionalInfo.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    <RichText
                      html={additionalInfo.info ? additionalInfo.info : ''}
                    ></RichText>
                  </div>
                </div>
              </div>
            )}
            {responsible && responsible.label && (
              <div className="factbox__contact__item">
                <div className="factbox__contact__item__icon">
                  <PersonIcon />
                </div>
                <div className="factbox__contact__item__content">
                  <div className="factbox__contact__item__content__title">
                    {responsible.label}
                  </div>
                  <div className="factbox__contact__item__content__block">
                    <RichText
                      html={responsible.info ? responsible.info : ''}
                    ></RichText>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <button
        className="factbox__toggle-button"
        onClick={toggle}
        aria-expanded={isOpen}
        aria-controls="show-more"
      >
        {isOpen ? showLessButtonText : showMoreButtonText}
        <ArrowIcon
          className={cn('factbox__toggle-button-arrow', {
            'factbox__toggle-button-arrow--is-open': isOpen,
          })}
        />
      </button>
    </div>
  );
};
