import React from 'react';
import { Image } from '../image/image';
import './_quote.scss';

interface QuoteBlockProps {
  image?: string;
  quote: string;
  author: string;
}

export const Quote = ({ image, quote, author }: QuoteBlockProps) => {
  return (
    <div className="quoteblock">
      <div className="quoteblock__content">
        {image && (
          <div className="quoteblock__image">
            <Image image={image} />
          </div>
        )}
        <div className="quoteblock__content-text">
          <div className="quoteblock__content-text-quote">{quote}</div>
          {author && (
            <div className="quoteblock__content-text-author">– {author}</div>
          )}
        </div>
      </div>
    </div>
  );
};
