import React from 'react';
import { Image } from '../image/image';
import { Heading } from '../heading/heading';
import { Button } from '../button/button';
import './_frontpage-hero.scss';

interface HeroProps {
  title: string;
  text: string;
  image?: string;
  video?: string;
  button?: {
    text: string;
    url: string;
  };
}

export const FrontpageHero = ({
  title,
  text,
  image,
  video,
  button,
}: HeroProps) => (
  <div className="frontpage-hero">
    <div className="frontpage-hero__content">
      <div className="frontpage-hero__title">
        <Heading level="h1" size="lg" color="blue-dark">
          {title}
        </Heading>
      </div>
      <div className="frontpage-hero__text">{text}</div>
      <div className="frontpage-hero__button">
        {button && <Button {...button} />}
      </div>
    </div>
    {image && <Image image={image} />}
        {video && (
        <video playsInline autoPlay muted loop>
        <source src={video} type="video/mp4"></source>
      </video>
    )}
  </div>
);
