import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import './_search-input.scss';

interface SearchInputProps {
  buttonText: string;
  onChange: any;
  searchQuery: any;
}

export const SearchInput = ({
  buttonText,
  onChange,
  searchQuery,
}: SearchInputProps) => {
  const [curr, setCurr] = React.useState('');
  const onInputChange = (e: any) => {
    setCurr(e.target.value);
  };
  return (
    <div className="search-input">
      <div className="search-input__input-field">
        <div className="search-input__icon">
          <SearchIcon />
        </div>
        <input
          className="search-input__input-field-text"
          type="search"
          defaultValue={searchQuery}
          onChange={onInputChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onChange('', '1', curr ? curr : '', 'all');
            }
          }}
        />
        <button
          onClick={(e) => onChange('', '1', curr ? curr : '', 'all')}
          className="search-input__button"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};
