import React from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import './_simple-locations-map.scss';

interface SimpleLocationMapProps {
  markerPositions: SimpleLocationProps[];
  apiKey: string;
  center: {
    lat: number;
    lng: number;
  };
  zoomLevel: number;
}

interface SimpleLocationProps {
  name?: string;
  lat: number;
  lng: number;
}

export const SimpleLocationsMap = ({
  markerPositions,
  apiKey,
  center,
  zoomLevel,
}: SimpleLocationMapProps) => {
  const mapOptions = {
    center: {
      lat: center.lat,
      lng: center.lng,
    },
    zoom: zoomLevel,
    disableDefaultUI: true,
  };

  const loader = new Loader({
    apiKey: apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  loader
    .load()
    .then((google) => {
      const map = new google.maps.Map(
        document.getElementById('map') as HTMLElement,
        mapOptions
      );

      // CUSTOM ZOOM
      function ZoomInControl(controlDiv: Element, map: google.maps.Map) {
        const controlUI = document.createElement('div');
        controlUI.classList.add('locations-map__zoom--in');
        controlUI.title = 'Zoom in';
        controlDiv.appendChild(controlUI);

        const controlText = document.createElement('div');
        controlText.classList.add('locations-map__zoom-text');

        controlText.style.color = '#CBEFF4';
        controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlText.style.fontSize = '21px';
        controlText.style.paddingLeft = '5px';
        controlText.style.paddingRight = '5px';
        controlText.innerHTML = '+';
        controlUI.appendChild(controlText);

        controlUI.addEventListener('click', () => {
          const currentZoom = map && map.getZoom();
          if (currentZoom) {
            map.setZoom(currentZoom + 1);
          }
        });
      }

      function ZoomOutControl(controlDiv: Element, map: google.maps.Map) {
        const controlUI = document.createElement('div');
        controlUI.classList.add('locations-map__zoom--out');
        controlUI.title = 'Zoom out';
        controlDiv.appendChild(controlUI);

        const controlText = document.createElement('div');
        controlText.classList.add('locations-map__zoom-text');

        controlText.style.color = '#CBEFF4';
        controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlText.style.fontSize = '30px';
        controlText.style.paddingLeft = '5px';
        controlText.style.paddingRight = '5px';
        controlText.innerHTML = '-';
        controlUI.appendChild(controlText);

        controlUI.addEventListener('click', () => {
          const currentZoom = map && map.getZoom();
          if (currentZoom) {
            map.setZoom(currentZoom - 1);
          }
        });
      }

      const zoomOutDiv = document.createElement('div');
      ZoomOutControl(zoomOutDiv, map);

      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomOutDiv);

      const zoomInDiv = document.createElement('div');
      ZoomInControl(zoomInDiv, map);
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomInDiv);

      // MARKERS
      if (markerPositions) {
        const markers =
          markerPositions &&
          markerPositions.map((marker) => {
            return new google.maps.Marker({
              position: marker,
              map: map,
              icon: {
                url: '/assets/icons/mappin.svg',
                size: new google.maps.Size(48, 48),
                scaledSize: new google.maps.Size(48, 48),
              },
            });
          });

        new MarkerClusterer({ map, markers });
      }
    })

    .catch((e) => {
      console.log(e);
    });

  return (
    <div className="locations-map">
      <div className="locations-map__map">
        <React.Fragment>
          <div id="map" className="map"></div>

          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${apiKey}v=weekly&libraries=places`}
            async
          ></script>
        </React.Fragment>
      </div>
    </div>
  );
};
