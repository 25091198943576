import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import './_menu-search.scss';
import cn from 'classnames';

interface MenuSearchProps {
  buttonText: string;
  searchPageName?: string;
}

export const MenuSearch = ({
  buttonText,
  searchPageName = 'sok',
}: MenuSearchProps) => {
  const [searchIsOpen, setSearchIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const openSearch = () => {
    setSearchIsOpen(true);
  };
  const closeSearch = () => {
    setSearchIsOpen(false);
  };

  const onChange = (value: string) => {
    setInputValue(value);
  };

  const redirectToSearchPage = () => {
    window.location.href = `${window.location.origin}/${searchPageName}/?q=${inputValue}`;
  };

  return (
    <div className="menu-search">
      {!searchIsOpen && (
        <button className="menu-search__button" onClick={openSearch}>
          <div className="menu-search__icon">
            <SearchIcon />
          </div>
          <div className="menu-search__search-button">{buttonText}</div>
        </button>
      )}

      <div
        className={cn('menu-search__input-field', {
          'menu-search__input-field--is-open': searchIsOpen,
        })}
      >
        <button onClick={closeSearch} className="menu-search__icon">
          <SearchIcon />
        </button>
        <input
          className={cn('menu-search__input-field-text', {
            'menu-search__input-field-text--is-open': searchIsOpen,
          })}
          type="search"
          ref={(inputRef) => inputRef && inputRef.focus()}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              redirectToSearchPage();
            }
          }}
          onChange={(e) => onChange(e.target.value)}
        />
        <button
          className="menu-search__search-button"
          onClick={redirectToSearchPage}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};
