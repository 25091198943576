import React from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { ReactComponent as SimpleArrow } from '../../assets/icons/simple-arrow.svg';
import './_button.scss';
import cn from 'classnames';

interface ButtonProps {
  text?: string;
  url?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  arrow?: boolean;
  simpleArrow?: boolean;
  color?: 'blue-dark' | 'blue-light';
  target?: string;
}

export const Button = ({
  text,
  url,
  onClick,
  className,
  arrow = false,
  simpleArrow = false,
  color = 'blue-dark',
  target,
}: ButtonProps) => {
  return url ? (
    <a
      className={cn('button', className, {
        [`button button--color-${color}`]: color,
      })}
      href={url}
      target={target ? target : ''}
    >
      <div className="button__text-container">
        <div className="button__text">{text}</div>
        {arrow && (
          <div className="button__arrow">
            <Arrow />
          </div>
        )}
      </div>
    </a>
  ) : (
    <button
      onClick={onClick}
      className={cn('button', className, {
        [`button button--color-${color}`]: color,
      })}
    >
      <div className="button__text-container">
        <div className="button__text">{text}</div>
        {arrow && (
          <div className="button__arrow">
            <Arrow />
          </div>
        )}
        {simpleArrow && (
          <div className="button__arrow">
            <SimpleArrow />
          </div>
        )}
      </div>
    </button>
  );
};
