import React from 'react';
import { Heading } from '../heading/heading';
import { RichText } from '../rich-text/rich-text';
import { Image } from '../image/image';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import './_employee-hero.scss';

interface EmployeeHeroProps {
  name: string;
  employeeTitle: string;
  description: string;
  image: string;
  phone: {
    label: string;
    text: string;
    url: string;
  };
  email: {
    label: string;
    text: string;
    url: string;
  };
}

export const EmployeeHero = ({
  name,
  employeeTitle,
  description,
  image,
  phone,
  email,
}: EmployeeHeroProps) => (
  <div className="employee-hero">
    <div className="employee-hero__content">
      <div className="employee-hero__title">
        <Heading level="h1" size="lg" color="blue-dark">
          {name}
        </Heading>
      </div>
      <div className="employee-hero__sub-title">{employeeTitle}</div>
      <p className="employee-hero__description">
        <RichText html={description ? description : ''}></RichText>
      </p>
      <div className="employee-hero__contact">
        {phone && phone.text && (
          <div className="employee-hero__contact-item">
            <div className="employee-hero__contact-icon">
              <PhoneIcon />
            </div>
            <div className="employee-hero__contact-info">
              <div className="employee-hero__contact-label">{phone.label}</div>
              <a className="employee-hero__contact-link" href={phone.url}>
                {phone.text}
              </a>
            </div>
          </div>
        )}
        {email && email.text && (
          <div className="employee-hero__contact-item">
            <div className="employee-hero__contact-icon">
              <EmailIcon />
            </div>
            <div className="employee-hero__contact-info">
              <div className="employee-hero__contact-label">{email.label}</div>
              <a className="employee-hero__contact-link" href={email.url}>
                {email.text}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
    <div className="employee-hero__image">
      <Image image={image} />
    </div>
  </div>
);
