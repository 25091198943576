import React from 'react';
import { GoogleMap } from '../google-maps/google-map';
import './_locations-map.scss';

interface LocationMapProps {
  locations: LocationProps[];
  apiKey: string;
  searchForLocationText?: string;
  loadingLocationsText?: string;
}

interface LocationProps {
  name: string;
  url: string;
  lat: number;
  lng: number;
  isVisible?: boolean;
}

export const LocationsMap = ({
  locations,
  apiKey,
  searchForLocationText,
  loadingLocationsText,
}: LocationMapProps) => {
  return (
    <div className="locations-map">
      <div className="locations-map__map">
        <div id="locations-input"></div>
        <div className="locations-map__loading" id="loading">
          {loadingLocationsText ?? 'Finding locations..'}
        </div>
        <GoogleMap
          apiKey={apiKey}
          markerPositions={locations}
          center={{ lat: 65, lng: 12 }}
          zoom={4}
          searchForLocationText={searchForLocationText ?? 'Search for location'}
        />
      </div>
      <div id="locations" className="locations-map__locations"></div>
    </div>
  );
};
