import './video.scss';

interface YoutubeProps {
    videoID: string;
    captionLabel: string;
    captionText: string;
    thumbnail?: string;
    width: number;
};

const handlePlayButtonClick = (event: any) => {
    const target = event.target as HTMLElement;
    const parent = target.parentElement;
    parent?.childNodes.forEach((element: ChildNode) => {
        const elem = element as HTMLElement;
        if (elem.classList?.contains("youtube-player")) { // Reload youtube IFrame, but with autoplay. Mimics actually playing the video
            const iframe = elem as HTMLIFrameElement;
            setTimeout(() => target.remove(), 450); // as to let the default YouTube button not be visible during iframe refresh
            iframe.src += "?autoplay=1";
        } else if (elem.classList?.contains("youtube-player-custom-thumbnail")) { // Hide custom thumbnail if there is one
            elem.style.display = "none";
        }
    });
};

export const YoutubeVideos = (props: YoutubeProps) => {
    let width = props.width;
    if (window.outerWidth < 600) {
        width = 100;
    }
    const height = (window.outerWidth / props.width) / 1.777777778;
    let thumbnailSrc = "";

    if (props.thumbnail && props.thumbnail?.length > 0) {
        try {
            const imgTitleStart = props.thumbnail.split('/globalassets/')[1];
            const imgTitle = imgTitleStart?.split('?')[0];
            thumbnailSrc = `/globalassets/${imgTitle}`;
        } catch (exception) {
            console.log(exception);
        }
    }

    return (
        <div className="embedded-youtube-video">
            <div className="embedded-youtube-video-container">
                {props.thumbnail && thumbnailSrc && (
                    <div className="youtube-player-custom-thumbnail" style={{
                        width: `${width}%`,
                        height: `${height}px`,
                        backgroundImage: `url(${thumbnailSrc})`,
                        position: "absolute",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }} data-width={width}>
                    </div>
                )}
                <div className="youtube-player-play-button" style={{
                    width: `${width}%`,
                    height: `${height}px`,
                    backgroundSize: "132px"/*(width > 66 ? "132px" : "78px")*/
                }} onClick={handlePlayButtonClick} data-width={width}></div>
                <iframe width={`${width}%`} height={`${height}px`} className="youtube-player" src={`https://www.youtube.com/embed/${props.videoID}`}
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen data-width={width}>
                </iframe>
            </div>
            <br />
            <b>{props.captionLabel}: </b><i>{props.captionText}</i>
        </div>
    );
}