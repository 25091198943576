import React from 'react';
import { Heading } from '../heading/heading';
import { Button } from '../button/button';
import { MenuSearch } from '../menu-search/menu-search';
import { ReactComponent as Logo } from '../../assets/logo/logo.svg';
import { ReactComponent as Arrow } from '../../assets/icons/simple-arrow.svg';

import useClickOutside from '../../hooks/useClickOutside.hook';
import useAnimateHeight from '../../hooks/useAnimateHeight.hook';
import useScrollListener from '../../hooks/useScrollListener.hook';
import cn from 'classnames';

import './_mega-menu.scss';

interface MegaMenuProps {
  home: {
    url: string;
    text: string;
  };
  locations: {
    url: string;
    text: string;
  };
  booking?: {
    url: string;
    text: string;
  };
  menuItems: MegaMenuBlockItem[];
  topLinks?: {
    url: string;
    text: string;
  }[];
  searchButtonText?: string;
  searchPageName: string;
  closeButtonLabel: string;
  openButtonLabel: string;
}

interface MegaMenuBlockItem {
  title: string;
  linksWithTitle?: {
    title: string;
    links: { text: string; url: string }[];
  }[];
  links: { text: string; url: string }[];
}

const MegaMenuItem = ({ title, linksWithTitle, links }: MegaMenuBlockItem) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [height, setHeight] = React.useState<number | undefined>(
    isOpen ? undefined : 0
  );

  const ref = React.useRef<HTMLLIElement>(null);
  const collapseRef = React.useRef<HTMLDivElement>(null);

  useAnimateHeight(isOpen, setHeight, collapseRef);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useClickOutside(
    ref,
    handleClickOutside,
    'mousedown',
    'mega-menu-block__button--mobile'
  );

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li role="menuitem" className="mega-menu-block__groups" ref={ref}>
      <button
        className="mega-menu-block__button"
        onClick={onMenuButtonClick}
        aria-expanded={isOpen}
      >
        <span className="mega-menu-block__button-text">{title}</span>
        <div
          className={cn('mega-menu-block__button-icon', {
            'mega-menu-block__button-icon--is-open': isOpen,
          })}
        >
          <Arrow />
        </div>
      </button>
      <button
        className="mega-menu-block__button mega-menu-block__button--mobile"
        onClick={onMenuButtonClick}
        aria-expanded={isOpen}
      >
        <span className="mega-menu-block__button-text">{title}</span>
        <div
          className={cn('mega-menu-block__button-icon', {
            'mega-menu-block__button-icon--is-open': isOpen,
          })}
        >
          <Arrow />
        </div>
      </button>

      <div
        style={{ height }}
        className={cn('mega-menu-block__items-container', {
          'mega-menu-block__items-container--is-open': isOpen,
        })}
      >
        <div ref={collapseRef} className="mega-menu-block__items">
          <div className="mega-menu-block__heading">
            <Heading level="h2" size="lg" color="white">
              {title}
            </Heading>
          </div>
          {linksWithTitle && (
            <div className="mega-menu-block__item mega-menu-block__item--with-title">
              {linksWithTitle.length !== 0 &&
                linksWithTitle.map((item) => (
                  <ul
                    key={item.title}
                    className="mega-menu-block__item-container"
                  >
                    <div className="mega-menu-block__item-title">
                      {item.title}
                    </div>
                    {item.links &&
                      item.links.length !== 0 &&
                      item.links.map((link) => (
                        <li
                          className="mega-menu-block__link-with-title"
                          key={link.text}
                        >
                          <a
                            tabIndex={isOpen ? 0 : -1}
                            className="mega-menu-block__link-text mega-menu-block__link-text--with-title"
                            href={link.url}
                          >
                            {link.text}
                          </a>
                        </li>
                      ))}
                  </ul>
                ))}
            </div>
          )}
          {links && (
            <ul className="mega-menu-block__item">
              {links.length !== 0 &&
                links.map((link) => (
                  <li className="mega-menu-block__link" key={link.text}>
                    <a
                      tabIndex={isOpen ? 0 : -1}
                      className="mega-menu-block__link-text"
                      href={link.url}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </li>
  );
};

export const MegaMenu = ({
  home,
  locations,
  booking,
  menuItems,
  topLinks,
  searchButtonText,
  searchPageName,
  closeButtonLabel,
  openButtonLabel,
}: MegaMenuProps) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [menuHiddenOnScroll, setMenuHiddenOnScroll] = React.useState(true);
  const scroll = useScrollListener();

  const onOpenMenuButtonClick = () => {
    setMenuIsOpen(!menuIsOpen);
  };
  const hasOpenMenuItem = document.getElementsByClassName(
    'mega-menu-block__items-container--is-open'
  ).length;
  React.useEffect(() => {
    if (hasOpenMenuItem > 0) {
      return;
    }
    if (scroll.y > 500 && scroll.y - scroll.lastY > 0)
      setMenuHiddenOnScroll(false);
    else {
      setMenuHiddenOnScroll(true);
    }
  }, [scroll.y, scroll.lastY, hasOpenMenuItem]);

  return (
    <nav
      aria-label="Main navigation"
      className={cn('mega-menu-block', {
        'mega-menu-block--hidden': !menuHiddenOnScroll && !menuIsOpen,
      })}
    >
      {topLinks && (
        <div className="mega-menu-block__top-links">
          <ul className="mega-menu-margin-fix">
            <li className="mega-menu-block__search">
              {searchButtonText && (
                <MenuSearch
                  searchPageName={searchPageName}
                  buttonText={searchButtonText}
                />
              )}
            </li>
            {topLinks.map((link) => (
              <li key={link.text}>
                <a href={link.url}>{link.text}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div
        className={cn('mega-menu-block__content-container', {
          'mega-menu-block__content-container--hidden':
            !menuHiddenOnScroll && !menuIsOpen,
        })}
      >
        <div className="mega-menu-block__top-content">
          <a
            aria-label={home.text}
            href={home.url}
            className="mega-menu-block__logo"
          >
            <Logo />
          </a>
          <button
            aria-label={menuIsOpen ? closeButtonLabel : openButtonLabel}
            aria-expanded={menuIsOpen}
            onClick={onOpenMenuButtonClick}
            className={cn('mega-menu-block__hamburger-button', {
              'mega-menu-block__hamburger-button--open': menuIsOpen,
            })}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div
          className={cn('mega-menu-block__content', {
            'mega-menu-block__content--is-open': menuIsOpen,
          })}
        >
          <ul role="menubar" className="mega-menu-block__groups-container">
            <li role="menuitem" className="mega-menu-block__groups">
              <a className="mega-menu-block__nav-link" href={home.url}>
                {home.text}
              </a>
            </li>
            {menuItems &&
              menuItems.length !== 0 &&
              menuItems.map((item) => (
                <MegaMenuItem key={item.title} {...item} />
              ))}
            {locations.text && locations.url && (
              <li role="menuitem" className="mega-menu-block__groups">
                <a className="mega-menu-block__nav-link" href={locations.url}>
                  {locations.text}
                </a>
              </li>
            )}

            {booking && booking.text && (
              <li
                role="menuitem"
                className="mega-menu-block__booking-button-container"
              >
                <Button
                  className="mega-menu-block__booking-button"
                  color="blue-dark"
                  {...booking}
                />
              </li>
            )}
          </ul>
          {topLinks && (
            <div className="mega-menu-block__top-links--mobile">
              <ul className="mega-menu-margin-fix">
                <li>
                  {searchButtonText && (
                    <MenuSearch
                      searchPageName={searchPageName}
                      buttonText={searchButtonText}
                    />
                  )}
                </li>
                {topLinks.map((link) => (
                  <li key={link.text}>
                    <a href={link.url}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
