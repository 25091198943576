import React from 'react';
import { Heading } from '../heading/heading';
import { ReactComponent as Logo } from '../../assets/logo/logo_light.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';
import './_footer.scss';

interface FooterProps {
  column1: {
    title: string;
    links: FooterColumnLink[];
  };
  column2: {
    title: string;
    links: FooterColumnLink[];
  };
  socialMediaLinks: {
    title: string;
    links: {
      socialMedium: 'facebook' | 'instagram' | 'linkedin' | 'youtube';
      link: {
        url: string;
        text: string;
      };
    }[];
  };
  linkToStartPage: string;
  linkToStartPageLabel: string;
}

interface FooterColumnLink {
  link: string;
  title: string;
}

export const Footer = ({
  column1,
  column2,
  socialMediaLinks,
  linkToStartPage,
  linkToStartPageLabel,
}: FooterProps) => (
  <footer className="footer">
    <div className="footer__content-container">
      <div className="footer__content">
        <div>
          <a
            aria-label={linkToStartPageLabel}
            href={linkToStartPage}
            className="footer__logo"
          >
            <Logo />
          </a>
        </div>

        {column1 && (
          <div className="footer__group">
            <Heading size="sm" level="h2" color="white">
              {column1.title}
            </Heading>
            <ul className="footer__link-list">
              {column1.links.map((item) => (
                <li className="footer__link-container" key={item.title}>
                  <a className="footer__link" href={item.link}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {column2 && (
          <div className="footer__group">
            <Heading size="sm" level="h2" color="white">
              {column2.title}
            </Heading>
            <ul className="footer__link-list">
              {column2.links.map((item) => (
                <li className="footer__link-container" key={item.title}>
                  <a className="footer__link" href={item.link}>
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {socialMediaLinks && socialMediaLinks.links.length !== 0 && (
          <div className="footer__group">
            <Heading size="sm" level="h2" color="white">
              {socialMediaLinks.title}
            </Heading>
            <ul className="footer__link-list">
              {socialMediaLinks.links.map((socialLink) => (
                <li
                  className="footer__link-container"
                  key={socialLink.link.text}
                >
                  <div className="footer__icon">
                    {socialLink.socialMedium === 'facebook' ? (
                      <FacebookIcon />
                    ) : socialLink.socialMedium === 'instagram' ? (
                      <InstagramIcon />
                    ) : socialLink.socialMedium === 'linkedin' ? (
                      <LinkedInIcon />
                    ) : socialLink.socialMedium === 'youtube' ? (
                      <YoutubeIcon />
                    ) : null}
                  </div>
                  <a className="footer__link" href={socialLink.link.url}>
                    {socialLink.link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </footer>
);
