import React from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { RichText } from '../rich-text/rich-text';
import './_google-map.scss';

interface Map {
  center: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  hasMarker?: boolean;
  markerPosition?: {
    lat: number;
    lng: number;
  };
  markerPositions?: {
    name: string;
    url: string;
    lat: number;
    lng: number;
    isVisible?: boolean;
  }[];
  apiKey: string;
  caption?: string;
  captionText?: string;
  searchForLocationText?: string;
}

export const GoogleMap = ({
  center,
  zoom = 13,
  hasMarker = true,
  markerPosition,
  markerPositions,
  apiKey,
  caption,
  captionText,
  searchForLocationText,
}: Map) => {
  const mapOptions = {
    center,
    zoom,
    disableDefaultUI: true,
  };

  const loader = new Loader({
    apiKey: apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  if (hasMarker) {
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(
          document.getElementById('map') as HTMLElement,
          mapOptions
        );

        const width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        // CUSTOM ZOOM
        function ZoomInControl(controlDiv: Element, map: google.maps.Map) {
          const controlUI = document.createElement('div');
          controlUI.classList.add('locations-map__zoom--in');
          controlUI.title = 'Zoom in';
          controlDiv.appendChild(controlUI);

          const controlText = document.createElement('div');
          controlText.classList.add('locations-map__zoom-text');

          controlText.style.color = '#CBEFF4';
          controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
          controlText.style.fontSize = '21px';
          controlText.style.paddingLeft = '5px';
          controlText.style.paddingRight = '5px';
          controlText.innerHTML = '+';
          controlUI.appendChild(controlText);

          controlUI.addEventListener('click', () => {
            const currentZoom = map && map.getZoom();
            if (currentZoom) {
              map.setZoom(currentZoom + 1);
            }
          });
        }

        function ZoomOutControl(controlDiv: Element, map: google.maps.Map) {
          const controlUI = document.createElement('div');
          controlUI.classList.add('locations-map__zoom--out');
          controlUI.title = 'Zoom out';
          controlDiv.appendChild(controlUI);

          const controlText = document.createElement('div');
          controlText.classList.add('locations-map__zoom-text');

          controlText.style.color = '#CBEFF4';
          controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
          controlText.style.fontSize = '30px';
          controlText.style.paddingLeft = '5px';
          controlText.style.paddingRight = '5px';
          controlText.innerHTML = '-';
          controlUI.appendChild(controlText);

          controlUI.addEventListener('click', () => {
            const currentZoom = map && map.getZoom();
            if (currentZoom) {
              map.setZoom(currentZoom - 1);
            }
          });
        }

        const zoomOutDiv = document.createElement('div');
        ZoomOutControl(zoomOutDiv, map);

        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomOutDiv);

        const zoomInDiv = document.createElement('div');
        ZoomInControl(zoomInDiv, map);
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomInDiv);

        // MARKERS
        markerPosition &&
          new google.maps.Marker({
            position: markerPosition,
            map: map,
            icon: {
              url: '/assets/icons/mappin.svg',
              size: new google.maps.Size(32, 32),
              scaledSize: new google.maps.Size(32, 32),
            },
          });
        if (markerPositions) {
          //GEOLOCATION
          var geoOptions = {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: Infinity,
          };

          const loadingDiv = document.getElementById('loading');
          loadingDiv?.classList.add('locations-map__loading--is-loading');

          const success = (pos: any) => {
            var crd = pos.coords;
            var initialLocation = new google.maps.LatLng(
              crd.latitude,
              crd.longitude
            );
            map.setCenter(initialLocation);
            map.setZoom(10);
            loadingDiv?.classList.remove('locations-map__loading--is-loading');
          };

          const error = (err: any) => {
            loadingDiv?.classList.remove('locations-map__loading--is-loading');
            console.warn(`ERROR(${err.code}): ${err.message}`);
          };

          navigator.geolocation.getCurrentPosition(success, error, geoOptions);

          // SEARCH
          const input = document.createElement('input');
          input.type = 'text';
          input.className = 'locations-map__search';
          input.placeholder = searchForLocationText ?? 'Search for location';

          const searchBox = new google.maps.places.SearchBox(input);

          map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
          });

          //Use Places Autocomplete
          var autocomplete = new google.maps.places.Autocomplete(input);
          autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            if (!place.geometry) {
              // User entered the name of a Place that was not suggested and
              // pressed the Enter key, or the Place Details request failed.
              window.alert(
                "No details available for input: '" + place.name + "'"
              );
              return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
              map.fitBounds(place.geometry.viewport);
            } else {
              map.setZoom(10);
            }
          });

          const markers =
            markerPositions &&
            markerPositions.map((marker) => {
              var m = new google.maps.Marker({
                position: marker,
                map: map,
                icon: {
                  url: '/assets/icons/mappin.svg',
                  size: new google.maps.Size(32, 32),
                  scaledSize: new google.maps.Size(32, 32),
                },
              });

              google.maps.event.addListener(m, 'click', function () {
                if (width <= 1000) {
                  window.location.href = marker.url;
                } else {
                  map.setCenter(marker);
                  map.setZoom(16);
                }
              });

              map.addListener('bounds_changed', () => {
                var bounds = map.getBounds();
                marker.isVisible = bounds && bounds.contains(marker);

                markerPositions &&
                  markerPositions.map((marker, index) => {
                    const id = document.getElementById(
                      'location-' + index.toString()
                    );

                    if (bounds && bounds.contains(marker)) {
                      id &&
                        id.classList.add('locations-map__location--is-visible');
                    } else {
                      id &&
                        id.classList.remove(
                          'locations-map__location--is-visible'
                        );
                    }
                  });
              });

              return m;
            });

          const loc = document.getElementById('locations') as HTMLDivElement;

          markerPositions &&
            markerPositions.map((marker, index) => {
              const li = document.createElement('li');
              li.classList.add('locations-map__location');
              li.setAttribute('id', 'location-' + index.toString());
              const a = document.createElement('a');
              a.setAttribute('href', marker.url);
              li.appendChild(a);
              a.innerHTML = `${marker.name}`;
              const svg = document.createElement('div');
              loc.appendChild(li);

              const svgContent =
                "<svg class='arrow' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'><g><g class='primary-arrow'><path d='M4.55005 7.65033L6.83997 5.13031H0V4.0603H6.83997L4.55005 1.54028L5.32007 0.800293L8.75 4.60028L5.32007 8.40033L4.55005 7.65033Z' fill='#F35A0C'/></g><g class='secondary-arrows'><path fill-rule='evenodd' clip-rule='evenodd' d='M9.03071 1.11523L12.1741 4.59063L9.03071 8.06602L8.28906 7.39523L10.8257 4.59063L8.28906 1.78602L9.03071 1.11523Z' fill='#F35A0C'/><path fill-rule='evenodd' clip-rule='evenodd' d='M12.5809 1.7356L15.1523 4.59024L12.5809 7.44489L11.8379 6.7756L13.8064 4.59024L11.8379 2.40489L12.5809 1.7356Z' fill='#F35A0C'/></g></g></svg>";

              svg.innerHTML = `${svgContent}`;
              a.appendChild(svg);
            });

          new MarkerClusterer({ map, markers });
        }
      })

      .catch((e) => {
        console.log(e);
      });
  } else {
    loader
      .load()
      .then((google) => {
        const map = new google.maps.Map(
          document.getElementById('map') as HTMLElement,
          mapOptions
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <React.Fragment>
      <div id="map" className="map"></div>
      {caption && (
        <div className="map__text">
          <div className="map__text--caption">
            {caption ? `${caption}:` : ''}
          </div>
          <RichText html={captionText ? captionText : ''}></RichText>
        </div>
      )}
      <script
        src={`https://maps.googleapis.com/maps/api/js?key=${apiKey}v=weekly&libraries=places`}
        async
      ></script>
    </React.Fragment>
  );
};
