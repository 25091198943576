import React from 'react';
import { Image } from '../image/image';
import { Heading } from '../heading/heading';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-red.svg';
import './_employee-cards.scss';

interface EmployeeCardsProps {
  title?: string;
  cards: {
    image: string;
    name: string;
    title: string;
    locations?: string;
    email?: string;
    phone?: string;
    url: string;
  }[];
  sendEmailText: string;
  disableLinks: boolean;
}

export const EmployeeCards = ({
  title,
  cards,
  sendEmailText,
  disableLinks,
}: EmployeeCardsProps) => (
  <div className="employee-cards">
    {title && (
      <div className="employee-cards__block-title">
        <Heading level="h2" size="sm" color="blue-dark">
          {title}
        </Heading>
      </div>
    )}
    {cards && cards.length !== 0 && (
      <ul className="employee-cards__items">
        {cards.map((card) => (
          <li key={card.name} className="employee-cards__item">
            <div className="employee-cards__content">
              <div className="employee-cards__info">
                {!disableLinks && (
                  <a className="employee-cards__link" href={card.url}>
                    <div className="employee-cards__link-container">
                      <div className="employee-cards__title">
                        <Heading level="h3" size="xs" color="white">
                          {card.name}
                        </Heading>
                        <div className="employee-cards__icon">
                          <Arrow />
                        </div>
                      </div>
                      <div className="employee-cards__card-title">
                        {card.title}
                      </div>
                      <div className="employee-cards__locations">
                        {card.locations && card.locations}
                      </div>
                    </div>
                  </a>
                )}
                {disableLinks && (
                  <div className="employee-cards__link-disabled">
                    <div className="employee-cards__link-container">
                      <div className="employee-cards__title">
                        <Heading level="h3" size="xs" color="white">
                          {card.name}
                        </Heading>
                      </div>
                      <div className="employee-cards__card-title">
                        {card.title}
                      </div>
                      <div className="employee-cards__locations">
                        {card.locations && card.locations}
                      </div>
                    </div>
                  </div>
                )}

                {card.email && !disableLinks && (
                  <div className="employee-cards__contact">
                    <div className="employee-cards__contact-icon">
                      <EmailIcon />
                    </div>
                    <a
                      href={`mailto:${card.email}`}
                      className="employee-cards__contact-text"
                    >
                      {sendEmailText}
                    </a>
                  </div>
                )}
                {card.phone && (
                  <div className="employee-cards__contact">
                    <div className="employee-cards__contact-icon">
                      <PhoneIcon />
                    </div>
                    <a
                      href={`tel:${card.phone}`}
                      className="employee-cards__contact-text"
                    >
                      {card.phone}
                    </a>
                  </div>
                )}
              </div>
              <div className="employee-cards__image">
                <Image image={card.image} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    )}
  </div>
);
