import React from 'react';
import cn from 'classnames';
import './_pagination.scss';

interface PaginationProps {
  pageCount: number;
  currentPage: string;
  onChange: (e: any) => void;
}

export const Pagination = ({
  pageCount,
  currentPage,
  onChange,
}: PaginationProps) => {
  const next = parseInt(currentPage) + 1;
  const previous = parseInt(currentPage) - 1;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ul className="pagination">
      {parseInt(currentPage) >= 2 && (
        <li className="pagination__item">
          <input
            className="pagination__input"
            onClick={(e) => {
              onChange(e);
              scrollToTop();
            }}
            id="previous"
            type="button"
            name="pagination"
            value={previous.toString()}
          />

          <label htmlFor="previous" className="pagination__label">{`<`}</label>
        </li>
      )}
      {[...Array(pageCount)].map((_, index) => (
        <li className="pagination__item">
          <input
            className="pagination__input"
            onClick={(e) => {
              onChange(e);
              scrollToTop();
            }}
            id={'page-' + (index + 1).toString()}
            type="radio"
            name="pagination"
            value={'page-' + (index + 1).toString()}
            checked={currentPage === (index + 1).toString()}
          />

          <label
            htmlFor={'page-' + (index + 1).toString()}
            className={cn('pagination__label', {
              'pagination__label--is-selected':
                currentPage === (index + 1).toString(),
            })}
          >
            {index + 1}
          </label>
        </li>
      ))}
      {parseInt(currentPage) < pageCount && (
        <li className="pagination__item">
          <input
            className="pagination__input"
            onClick={(e) => {
              onChange(e);
              scrollToTop();
            }}
            id="next"
            type="button"
            name="pagination"
            value={next.toString()}
          />

          <label htmlFor="next" className="pagination__label">
            {`>`}
          </label>
        </li>
      )}
    </ul>
  );
};
