import React from 'react';
import { Heading } from '../heading/heading';
import { Image } from '../image/image';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-vector.svg';
import { ReactComponent as RedArrow } from '../../assets/icons/arrow-red.svg';
import './_article-card.scss';

interface ArticleCardProps {
  stackOfCards: Card[];
  blockTitle?: string;
  linkToMoreResults?: {
    text: string;
    url: string;
  };
  readMoreText?: string;
}

interface Card {
  image: string;
  title: string;
  description: string;
  url: string;
  readMoreText?: string;
}

export const ArticleCard = ({
  image,
  title,
  description,
  url,
  readMoreText,
}: Card) => {
  return (
    <a className="article-card__card-top-link" href={url}>
      <div className="article-card__card">
        <div className="article-card__card-image">
          <Image image={image} />
        </div>
        <div className="article-card__card-content">
          <Heading level="h2" size="xs" color="blue-dark">
            {title}
          </Heading>
          <div className="article-card__card-description">{description}</div>
        </div>
        {url && (
          <a className="article-card__card-link" href={url}>
            {readMoreText}
            <div className="article-card__card-link-icon">
              <Arrow />
            </div>
          </a>
        )}
      </div>
    </a>
  );
};

export const ArticleCards = ({
  stackOfCards,
  blockTitle,
  linkToMoreResults,
  readMoreText,
}: ArticleCardProps) => {
  return (
    <div className="article-card">
      {blockTitle && (
        <div className="article-card__title">
          <Heading level="h4" size="sm" color="blue-dark">
            {blockTitle}
          </Heading>
        </div>
      )}
      <div className="article-card__cards">
        {stackOfCards &&
          stackOfCards.length !== 0 &&
          stackOfCards.map((card) => (
            <ArticleCard
              readMoreText={readMoreText}
              key={card.title}
              {...card}
            ></ArticleCard>
          ))}
      </div>
      {linkToMoreResults && (
        <a className="article-card__link" href={linkToMoreResults.url}>
          {linkToMoreResults.text}
          <div className="article-card__link-icon">
            <RedArrow />
          </div>
        </a>
      )}
    </div>
  );
};
