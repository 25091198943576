function handleWidthAndHeightOfYoutubeEmbeds() {
    const embeds = document.getElementsByClassName('embedded-youtube-video-container');

    for (let i = 0; i < embeds.length; i++) {
        const embedParent = embeds.item(i) as HTMLElement;
        const children = embedParent.children;
        for (let j = 0; j < children.length; j++) {
            const embedElement = children.item(j) as any;
            const height = `${(embedElement.clientWidth / 1.777778)}px`;
            embedElement.style.height = height;

            if (window.outerWidth < 500) {
                embedElement.style.width = "100%";
            } else {
                embedElement.style.width = `${embedElement.dataset.width}%`;
            }

            if (embedElement.classList.contains('youtube-player-play-button')) {
                const width = embedElement.clientWidth;
                if (width > 1100) {
                    embedElement.style.backgroundSize = "132px";
                } else if (width > 600) {
                    embedElement.style.backgroundSize = "108px";
                } else {
                    embedElement.style.backgroundSize = "78px";
                }
            }
        }
    }
}

window.addEventListener('resize', handleWidthAndHeightOfYoutubeEmbeds);

export const VideoHandler = () => {
    handleWidthAndHeightOfYoutubeEmbeds();
};;