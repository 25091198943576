import React from 'react';
import { Heading } from '../heading/heading';
import { RichText } from '../rich-text/rich-text';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/x.svg';
import './_system-message-block.scss';

interface SystemMessageBlockProps {
  messages: Message[];
}

interface Message {
  title: string;
  text: string;
  level?: 'informative' | 'warning' | 'critical';
  id: string;
  closeButtonText: string;
}

const SystemMessageItem = ({
  title,
  text,
  level = 'informative',
  id,
  closeButtonText,
}: Message) => {
  const [closed, setIsClosed] = React.useState(false);

  const closeMessage = () => {
    let date = new Date();
    date.setTime(date.getTime() + 168 * 60 * 60 * 1000);
    document.cookie =
      'evidia-system-message' +
      id +
      ' = ' +
      true +
      '; expires = ' +
      date.toUTCString() +
      '; path=/';
    setIsClosed(true);
  };

  function checkIfCookieIsSet() {
    var match = document.cookie.match(
      RegExp('(?:^|;\\s*)evidia-system-message' + id + '=([^;]*)')
    );
    const isSet = match ? match[1] : null;
    setIsClosed(isSet === 'true' ? true : false);
  }

  React.useEffect(() => {
    checkIfCookieIsSet();
  });

  return (
    <React.Fragment>
      {!closed && (
        <li
          className={`system-message-block__item system-message-block__item--${level}`}
        >
          {level !== 'critical' && (
            <div className="system-message-block__item-close-button-container">
              <button
                aria-label={closeButtonText}
                onClick={closeMessage}
                className="system-message-block__item-close-button"
              >
                <CloseIcon />
              </button>
            </div>
          )}
          <div className="system-message-block__item-title-container">
            <div className="system-message-block__item-icon">
              <InfoIcon />
            </div>
            <Heading level="h2" size="xs" color="black">
              {title}
            </Heading>
          </div>
          <div className="system-message-block__item-text">
            <RichText html={text} />
          </div>
        </li>
      )}
    </React.Fragment>
  );
};

export const SystemMessageBlock = ({ messages }: SystemMessageBlockProps) => (
  <ul className="system-message-block">
    {messages &&
      messages.length !== 0 &&
      messages.map((message) => (
        <SystemMessageItem key={message.title} {...message}></SystemMessageItem>
      ))}
  </ul>
);

SystemMessageBlock.defaultProps = {
  theme: 'informative',
};
