import React from 'react';
import './_promo.scss';
import { RichText } from '../rich-text/rich-text';
import { Button } from '../button/button';
import { Heading } from '../heading/heading';

interface PromoBlockProps {
  title?: string;
  text: string;
  button1?: {
    url: string;
    text: string;
  };
  button2?: {
    url: string;
    text: string;
  };
  button3?: {
    url: string;
    text: string;
  };
}

export const Promo = ({
  title,
  text,
  button1,
  button2,
  button3,
}: PromoBlockProps) => (
  <div className="promo">
    <div className="promo__content">
      {title && (
        <div className="promo__title">
          <Heading level="h2" size="sm" color="blue-dark">
            {title}
          </Heading>
        </div>
      )}
      {text && (
        <div className="promo__text">
          <RichText html={text ? text : ''}></RichText>
        </div>
      )}
      <div className="promo__buttons">
        {button1 && (
          <div className="promo__button">
            {button1?.text && <Button {...button1}></Button>}
          </div>
        )}
        {button2 && (
          <div className="promo__button">
            {button2?.text && <Button {...button2}></Button>}
          </div>
        )}
        {button3 && (
          <div className="promo__button">
            {button3?.text && <Button {...button3}></Button>}
          </div>
        )}
      </div>
    </div>
  </div>
);
