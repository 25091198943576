import React from 'react';
import { Heading } from '../heading/heading';
import { ReactComponent as Arrow } from '../../assets/icons/arrow-red.svg';
import './_link-list.scss';

interface LinkListProps {
  heading: string;
  links?: {
    text: string;
    url: string;
    target: string;
  }[];
}

export const LinkList = ({ heading, links }: LinkListProps) => {
  return (
    <div className="link-list">
      <div className="link-list__heading">
        <Heading level="h5" size="xs" color="blue-dark">
          {heading}
        </Heading>
      </div>
      <div className="link-list__links">
        <ul>
          {links &&
            links.length > 0 &&
            links.map((link) => (
              <li>
                <div className="link-list__link">
                  <a href={link.url} key={link.text} target={link.target}>
                    {link.text}
                    <div className="link-list__link-arrow">
                      <Arrow />
                    </div>
                  </a>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
