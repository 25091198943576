import React from 'react';
import './_heading.scss';

// the interface needs to explicitly declare which strings are safe to pass
interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" 
  size: "xxl" | "xl" | "lg" | "md" | "sm" | "xs"
  color: "blue-dark" | "blue-light" | "white" | "black"
}

export const Heading = ({
  level = "h2",
  children,
  size = 'xl',
  color = 'blue-dark',
  className = `heading heading--size-${size} heading--color-${color}`,
}: HeadingProps) => {
  const Heading = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(level, props, children)

  return <Heading className={className} >{children}</Heading>
}