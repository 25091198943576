import React from 'react';
import { Button } from '../button/button';
import { Heading } from '../heading/heading';
import cn from 'classnames';
import useAnimateHeight from '../../hooks/useAnimateHeight.hook';
import './_category-tags.scss';

interface CategoryTagsProps {
  title: string;
  tags: {
    label: string;
    url: string;
  }[];
}

export const CategoryTags = ({ title, tags }: CategoryTagsProps) => {
  const [dropdownIsOpen, setDropDownIsOpen] = React.useState(false);
  const [height, setHeight] = React.useState<number | undefined>(
    dropdownIsOpen ? undefined : 0
  );
  const collapseRef = React.useRef<HTMLDivElement>(null);

  useAnimateHeight(dropdownIsOpen, setHeight, collapseRef);

  const toggleDropdown = () => {
    setDropDownIsOpen(!dropdownIsOpen);
  };

  return (
    <div className="category-tags">
      {title && (
        <div className="category-tags__title">
          <Heading level="h2" size="sm" color="blue-dark">
            {title}
          </Heading>
        </div>
      )}
      {tags && tags.length !== 0 && (
        <div className="category-tags__container">
          {title && (
            <div className="category-tags__button-container">
              <Button
                simpleArrow={true}
                className={cn('category-tags__button', {
                  'category-tags__button--is-open': dropdownIsOpen,
                })}
                onClick={toggleDropdown}
                text={title}
              />
            </div>
          )}

          <ul
            style={{ height }}
            className={cn('category-tags__list category-tags__list--mobile', {
              'category-tags__list--is-open': dropdownIsOpen,
            })}
          >
            <div ref={collapseRef}>
              {tags.map((tag) => (
                <li key={tag.label} className="category-tags__item">
                  <a href={tag.url}>{tag.label}</a>
                </li>
              ))}
            </div>
          </ul>

          <ul className="category-tags__list category-tags__list--desktop">
            {tags.map((tag) => (
              <li key={tag.label} className="category-tags__item">
                <a href={tag.url}>{tag.label}</a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
